import React from 'react';

import useActiveVariationSafely from '../../utils/figpii/useActiveVariationSafely';
import CustomerFeedback from '../Home/patterns/CustomerFeedback/CustomerFeedback';
import Hero from '../../components/Hero/Hero';
import FAQ from '../../components/FAQ/FAQ';
import ProductCard from '../../components/ProductCard/ProductCard';
import NuMessage from '../../components/NuMessage/NuMessage';
import ReasonsBelongingPurple from '../../components/ReasonsBelongingPurple/ReasonsBelongingPurple';
import NuDisclaimer from '../../components/NuDisclaimer/NuDisclaimer';
import ValueProposal from '../../components/ValueProposal/ValueProposal';
import FixedBanner from '../../components/FixedBanner/FixedBanner';
import { CULTURAL_HACK, REDEBAN } from '../../utils/experiments';

import ComponentDefaultProps, { CulturalHackTreatmentAProps, CulturalHackTreatmentBProps, RedebanTreatmentProps } from './variant/MultiProductHomeProps';
import HomePropType from './variant/HomePropTypes';
import Perks from './patterns/Perks/Perks';
import PressSection from './patterns/Press/Press';
import Blog from './patterns/Blog/Blog';

const MultiProductHomePage = () => {
  const activeVariant = useActiveVariationSafely(CULTURAL_HACK.id);
  const activeRedebanVariant = useActiveVariationSafely(REDEBAN.id);

  const variantPropsMap = {
    [CULTURAL_HACK.treatmentA]: CulturalHackTreatmentAProps,
    [CULTURAL_HACK.treatmentB]: CulturalHackTreatmentBProps,
    [CULTURAL_HACK.control]: ComponentDefaultProps,
  };

  const setPropsByVariantPriority = () => {
    if (activeRedebanVariant === REDEBAN.treatment) {
      return RedebanTreatmentProps;
    }
    if (activeVariant != null && activeVariant !== 'VARIANT_NOT_FOUND') {
      return variantPropsMap[activeVariant];
    }
    return ComponentDefaultProps;
  };

  const {
    hero,
    disclaimer,
    savingsProduct,
    savingsPerks,
    creditsProduct,
    creditsPerks,
    perks,
    reasons,
    press,
    blog,
    faq,
    feedbacks,
    nuMessage,
    fixedBanner,
  } = setPropsByVariantPriority();

  return (
    <>
      <FixedBanner {...fixedBanner} />
      <Hero {...hero} />
      <NuDisclaimer {...disclaimer} />
      <ProductCard {...savingsProduct} />
      <ValueProposal {...savingsPerks} />
      <ProductCard {...creditsProduct} />
      <ValueProposal {...creditsPerks} />
      <Perks {...perks} />
      <ReasonsBelongingPurple {...reasons} />
      <CustomerFeedback feedbacks={feedbacks} />
      <PressSection {...press} />
      <NuMessage {...nuMessage} />
      <Blog {...blog} />
      <FAQ {...faq} />
    </>
  );
};

MultiProductHomePage.propTypes = HomePropType;

export default MultiProductHomePage;
