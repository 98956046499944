import React from 'react';

import websitePage from 'hoc/websitePage/websitePage';
import MultiProductHome from 'screens/MultiProductHome/MultiProductHome';

import HiddeableHeaderCTA from '../../components/HiddeableHeaderCTA/HiddeableHeaderCTA';
import { companyFooterTypes } from '../../patterns/MultiProductFooter/patterns/company/FooterCompany';
import MultiProductFooter, { footerTypes } from '../../patterns/MultiProductFooter/MultiProductFooter';

const HERO_HEIGHT_MOBILE = 647;
const HERO_HEIGHT_TABLET = 1135;
const HERO_HEIGHT_DESKTOP = 723;

const loadHomePage = (routeKey, openGraphResource) => websitePage(MultiProductHome, {
  routeKey,
  footerType: footerTypes.FULL,
  companyFooterType: companyFooterTypes.FULL,
  components: {
    footer: MultiProductFooter,
  },
  openGraphResource,
  header: {
    cta: (
      <HiddeableHeaderCTA
        hiddenHeight={{
          xs: HERO_HEIGHT_MOBILE,
          sm: HERO_HEIGHT_TABLET,
          md: HERO_HEIGHT_DESKTOP,
          lg: HERO_HEIGHT_DESKTOP,
          xl: HERO_HEIGHT_DESKTOP,
        }}
        btnLabel="MULTI_PRODUCT_HOME.HERO.FORM.BUTTON_LABEL"
        styleVariant="primary"
      />
    ),
    nuLogoHref: '/',
  },
});

export default loadHomePage;
