import { useEffect, useState } from 'react';

const useActiveVariationSafely = id => {
  const [activeVariant, setActiveVariant] = useState(null);
  useEffect(() => {
    const asyncVariationLoadedHandler = event => {
      const { toolId, variationId } = event.detail;
      if (toolId === id && variationId !== '-1') {
        setActiveVariant(variationId);
      }
    };

    if (typeof window !== 'undefined' && typeof window.FIGPII !== 'undefined') {
      const activeVariations = window.FIGPII.experimentManager?.getActiveVariations();
      if (activeVariations && activeVariations[id]) {
        const decidedVariation = activeVariations[id];
        setActiveVariant(decidedVariation);
      } else {
        setActiveVariant('VARIANT_NOT_FOUND');
        window.addEventListener('figpii::experiment.variation.loaded', asyncVariationLoadedHandler);
      }
    }

    return () => {
      window.removeEventListener('figpii::experiment.variation.loaded', asyncVariationLoadedHandler);
    };
  }, []);
  return activeVariant;
};

export default useActiveVariationSafely;
